import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { space, fontSizes, colors, COLOR_CONSTANTS } from 'theme'
import {
  CONTAINER_MAX_WIDTH,
  SITE_LINK_PUBLISHING,
  SITE_LINK_ANALYTICS,
  SITE_LINK_LISTENING,
  SITE_LINK_REVIEWS,
  SITE_LINK_ENGAGEMENT,
  SITE_LINK_AI,
  SITE_LINK_MOBILE_APP,
  DOWNLOAD_APP_GOOGLE_LINK,
  DOWNLOAD_APP_APPLE_LINK,
} from 'consts'
import { Box, Flex, Grid } from './Layout'
import { Text } from './Typography'

import logoBigUkraine from '../static/svg/logo_big.svg'

import facebookIcon from '../static/svg/facebook_icon.svg'
import instagramIcon from '../static/svg/instagram_icon.svg'
import linkedinIcon from '../static/svg/linkedin_icon.svg'
import twitterIcon from '../static/svg/twitter_icon.svg'
import youtubeIcon from '../static/svg/youtube_icon.svg'
import tiktokIcon from '../static/svg/tiktok_icon.svg'

import downloadGoogle from '../static/svg/download-google-play.svg'
import downloadIOS from '../static/svg/download-app-store.svg'
import usersLoveUs from '../static/images/g2_badges/momentum_2024.svg'
import highPerformer from '../static/images/g2_badges/performer_2024.svg'
import leader from '../static/images/g2_badges/best_usability_2024.svg'
import { SITE_LINK_ADVOCACY } from '../consts'

const Logo = styled.img`
  height: auto;
  object-fit: contain;
  margin-top: -18px;
`

const DownloadImg = styled.img`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
`

const StyledImg = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: filter 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  :not(:hover) {
    filter: saturate(0%) brightness(100%) contrast(100%);
  }
`

const BadgeImg = styled.img`
  padding-right: ${space.xs};
  padding-left: ${space.xs};
  cursor: pointer;
  width: 95px;
`

const StyledLink = styled(Text)`
  margin-bottom: ${space.s};
  padding-bottom: ${space.xs};
  text-decoration: none;
  color: ${colors.secondaryText};
  &:hover {
    color: ${colors.primary};
  }
`

const FooterLink = styled(Link)`
  font-size: ${fontSizes.xs};
  text-decoration: none;
  color: ${colors.secondaryText};
  &:hover {
    color: ${colors.primary};
  }
`

const StyledFlex = styled(Flex)`
  background: ${COLOR_CONSTANTS.WHITE};
  width: 100%;
`

const NETWORKS = [
  { link: 'https://instagram.com/vistasocialapp', icon: instagramIcon, title: 'Vista Social Instagram' },
  { link: 'https://www.linkedin.com/company/vistasocial', icon: linkedinIcon, title: 'Vista Social LinkedIn' },
  { link: 'https://youtube.com/@vistasocialapp', icon: youtubeIcon, title: 'Vista Social YouTube' },
  { link: 'https://www.tiktok.com/@vistasocialapp', icon: tiktokIcon, title: 'Vista Social TikTok' },
  { link: 'https://twitter.com/vistasocialapp', icon: twitterIcon, title: 'Vista Social Twitter' },
  { link: 'https://facebook.com/vistasocialapp', icon: facebookIcon, title: 'Vista Social Facebook' },
]

const Footer = () => {
  return (
    <StyledFlex>
      <Flex mx="auto" width="100%" maxWidth={CONTAINER_MAX_WIDTH}>
        <Box width="100%" mt="l" pt="l" mb="m" px="l">
          <Grid gridTemplateColumns={{ mobile: '1fr', desktop: 'auto 1fr' }} gridGap="m">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              alignItems={{ mobile: 'center', desktop: 'unset' }}
            >
              <Box maxWidth="200px" mt="m">
                <Logo src={logoBigUkraine} width="200px" />
              </Box>
              <Flex flexDirection="column" alignItems={{ mobile: 'center', desktop: 'unset' }}>
                <Flex mt="m" flexWrap="wrap" alignItems="center">
                  {NETWORKS.map(({ link, icon, title }) => (
                    <Text px="xs" key={link} mr="s" as="a" href={link} target="_new" rel="noopener noreferrer nofollow">
                      <StyledImg src={icon} alt={title} />
                    </Text>
                  ))}
                </Flex>
                <Flex mt="m" flexWrap="wrap" alignItems="center">
                  <Text as="a" href={DOWNLOAD_APP_GOOGLE_LINK} target="_new" rel="noopener noreferrer nofollow">
                    <DownloadImg src={downloadGoogle} alt="Download Vista Social Android App" />
                  </Text>

                  <Text as="a" href={DOWNLOAD_APP_APPLE_LINK} target="_new" rel="noopener noreferrer nofollow">
                    <DownloadImg src={downloadIOS} alt="Download Vista Social IOS App" />
                  </Text>
                </Flex>

                <Flex mt="m" flexWrap="wrap" alignItems="center">
                  <Text
                    as="a"
                    href="https://www.g2.com/products/vista-social"
                    target="_new"
                    rel="noopener noreferrer nofollow"
                  >
                    <BadgeImg src={highPerformer} alt="Vista Social G2 High Performer" />
                  </Text>
                  <Text
                    as="a"
                    href="https://www.g2.com/products/vista-social"
                    target="_new"
                    rel="noopener noreferrer nofollow"
                  >
                    <BadgeImg src={usersLoveUs} alt="Vista Social G2 Users Love Us" />
                  </Text>
                  <Text
                    as="a"
                    href="https://www.g2.com/products/vista-social"
                    target="_new"
                    rel="noopener noreferrer nofollow"
                  >
                    <BadgeImg src={leader} alt="Vista Social G2 Leader" />
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex flexDirection={{ mobile: 'column', mobileLarge: 'row' }} justifyContent="space-around">
              <Flex
                mt={{ mobile: 'm', mobileLarge: 0 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', mobileLarge: 'flex-start' }}
              >
                <Text
                  fontSize={{ mobile: 'l', tablet: 'm' }}
                  mb="m"
                  pb="s"
                  color={COLOR_CONSTANTS.DENIM}
                  fontWeight="bold"
                >
                  Tools
                </Text>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_PUBLISHING}>
                  Publishing
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_ANALYTICS}>
                  Analytics
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_ENGAGEMENT}>
                  Engagement
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_LISTENING}>
                  Listening
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_REVIEWS}>
                  Review Management
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_ADVOCACY}>
                  Employee Advocacy
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_AI}>
                  AI Assistant
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/extensions/">
                  Browser Extensions
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to={SITE_LINK_MOBILE_APP}>
                  Mobile
                </StyledLink>
              </Flex>

              <Flex
                mt={{ mobile: 'm', mobileLarge: 0 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', mobileLarge: 'flex-start' }}
              >
                <Text
                  fontSize={{ mobile: 'l', tablet: 'm' }}
                  mb="m"
                  pb="s"
                  color={COLOR_CONSTANTS.DENIM}
                  fontWeight="bold"
                >
                  Integrations
                </Text>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/facebook/">
                  Facebook
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/instagram/">
                  Instagram
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/linkedin/">
                  LinkedIn
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/threads/">
                  Threads
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/tiktok/">
                  TikTok
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/twitter/">
                  X (Twitter)
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/pinterest/">
                  Pinterest
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/vista-page/">
                  Vista Page (link-in-bio)
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/integrations/">
                  All Integrations
                </StyledLink>
              </Flex>

              <Flex
                mt={{ mobile: 'm', mobileLarge: 0 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', mobileLarge: 'flex-start' }}
              >
                <Text
                  fontSize={{ mobile: 'l', tablet: 'm' }}
                  mb="m"
                  pb="s"
                  color={COLOR_CONSTANTS.DENIM}
                  fontWeight="bold"
                >
                  Company
                </Text>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/about/">
                  About
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/faq/">
                  FAQ
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/careers/">
                  Careers
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/nonprofits/">
                  Nonprofits
                </StyledLink>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  to="https://suggestions.vistasocial.com/roadmap"
                  target="_blank"
                >
                  Product Roadmap
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/affiliate-marketing/">
                  Affiliates
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/social-media-tools/" target="_blank">
                  Free Tools
                </StyledLink>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  target="_blank"
                  to="https://www.g2.com/products/vista-social/reviews"
                >
                  G2 Reviews
                </StyledLink>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  target="_blank"
                  to="https://www.capterra.com/p/239366/Vista-Social/"
                >
                  Capterra Reviews
                </StyledLink>
              </Flex>

              <Flex
                mt={{ mobile: 'm', mobileLarge: 0 }}
                flexDirection="column"
                alignItems={{ mobile: 'center', mobileLarge: 'flex-start' }}
              >
                <Text
                  fontSize={{ mobile: 'l', tablet: 'm' }}
                  mb="m"
                  pb="s"
                  color={COLOR_CONSTANTS.DENIM}
                  fontWeight="bold"
                >
                  Resources
                </Text>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  to="https://support.vistasocial.com"
                  target="_blank"
                >
                  Help Center
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/insights/">
                  Blog
                </StyledLink>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  to="https://www.youtube.com/@vistasocialapp"
                  target="_blank"
                >
                  Tutorial Videos
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/podcast/" target="_blank">
                  Podcast
                </StyledLink>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  to="https://suggestions.vistasocial.com/"
                  target="_blank"
                >
                  Request Feature
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/brand-assets/">
                  Brand Assets
                </StyledLink>
                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  to="https://twitter.com/vistasocialapp"
                  target="_blank"
                >
                  Tweet @ Us
                </StyledLink>

                <StyledLink
                  fontSize={{ mobile: 'm', tablet: 's' }}
                  as={Link}
                  to="https://vistasocial.freshstatus.io"
                  target="_blank"
                >
                  Status
                </StyledLink>
                <StyledLink fontSize={{ mobile: 'm', tablet: 's' }} as={Link} to="/contact/">
                  Contact
                </StyledLink>
              </Flex>
            </Flex>
          </Grid>
          <Flex
            mt="l"
            width="100%"
            alignItems="center"
            pb="xss"
            mb="xxs"
            flexDirection={{ mobile: 'column', tablet: 'row' }}
          >
            <Text fontSize="xs" color="secondaryText">
              Copyright © {new Date().getFullYear()} Vista Social LLC. All Rights Reserved.
            </Text>
            <Flex alignItems="center" color="secondaryText" ml="s">
              <FooterLink as={Link} to="/privacy/">
                Privacy
              </FooterLink>
              <Text as="span" fontWeight="normal" fontSize="xs" px="s">
                {' | '}
              </Text>
              <FooterLink as={Link} to="/terms/">
                Terms
              </FooterLink>
              <Text as="span" fontWeight="normal" fontSize="xs" px="s">
                {' | '}
              </Text>
              <FooterLink as={Link} to="/security/">
                Security
              </FooterLink>
              <Text as="span" fontWeight="normal" fontSize="xs" px="s">
                {' | '}
              </Text>
              <FooterLink as={Link} to="/responsible-disclosure-policy/">
                Disclosure Policy
              </FooterLink>
              <Text as="span" fontWeight="normal" fontSize="xs" px="s">
                {' | '}
              </Text>
              <FooterLink as={Link} to="/gdpr/">
                GDPR
              </FooterLink>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </StyledFlex>
  )
}

export default Footer
